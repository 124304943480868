import React from 'react';
import styles from './styles.module.css';
import { SitesImageInCurlyMaskProps } from '../model/types';
import useIsMobile from '@/shared/hooks/useIsMobile';

/**
 * @component
 *
 * Компонент конструктора.
 *
 * `GSITE-242`
 * `Компонент "Контент с изображением в фигурной маске"`
 *
 */

export const SitesImageInCurlyMask: React.FC<SitesImageInCurlyMaskProps> = ({
  topDescription,
  title,
  mainColor,
  description,
  imageSrc,
  isReverse,
  backgroundColor,
  descriptionColor,
  imageMask,
  linkForButton,
  buttonText,
  listInDescription,
}) => {
  const { isMobile } = useIsMobile();

  const getImageMask = (imageMask: 'circle' | 'arch' | 'reversed arch') => {
    if (imageMask === 'circle') return '50%';
    if (imageMask === 'arch') return '50% 50% 0 0';
    if (imageMask === 'reversed arch') return '0 0 50% 50%';
  };
  return (
    <div className={styles.container} style={{ backgroundColor: backgroundColor ? backgroundColor : '' }}>
      {topDescription && (
        <h3 className={styles.topDescription} style={{ color: mainColor ? mainColor : '' }}>
          {topDescription}
        </h3>
      )}

      <div className={styles.imageWithTextContainer}>
        {imageSrc && (
          <div
            className={styles.imageBlock}
            style={{
              borderRadius: imageMask ? getImageMask(imageMask) : '',
              order: isReverse ? '2' : '1',
            }}
          >
            <img src={imageSrc} className={styles.image} alt="image" draggable="false" />
          </div>
        )}

        <div className={styles.textBlock} style={{ order: isReverse ? '1' : '2' }}>
          {title && (
            <h2 className={styles.title} style={{ color: mainColor ? mainColor : '' }}>
              {title}
            </h2>
          )}
          <div className={styles.textWithButton}>
            <div>
              {description && (
                <p className={styles.description} style={{ color: descriptionColor ? descriptionColor : '' }}>
                  {description}
                </p>
              )}
              {listInDescription && (
                <ul className={styles.ul}>
                  {listInDescription.map((element, index) => (
                    <li
                      key={index}
                      className={styles.description}
                      style={{ color: descriptionColor ? descriptionColor : '' }}
                    >
                      {element}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {!isMobile
              ? (linkForButton || buttonText) && (
                  <a
                    href={linkForButton ? linkForButton : '#'}
                    target="_blank"
                    className={styles.button}
                    style={{ borderColor: mainColor ? mainColor : '' }}
                    rel="noopener noreferrer"
                    draggable="false"
                  >
                    {buttonText && (
                      <p className={styles.buttonText} style={{ color: mainColor ? mainColor : '' }}>
                        {buttonText}
                      </p>
                    )}
                  </a>
                )
              : ''}
          </div>
        </div>
      </div>
      {isMobile
        ? (linkForButton || buttonText) && (
            <a
              href={linkForButton ? linkForButton : '#'}
              target="_blank"
              className={styles.button}
              style={{ borderColor: mainColor ? mainColor : '' }}
              rel="noopener noreferrer"
              draggable="false"
            >
              {buttonText && (
                <p className={styles.buttonText} style={{ color: mainColor ? mainColor : '' }}>
                  {buttonText}
                </p>
              )}
            </a>
          )
        : ''}
    </div>
  );
};
