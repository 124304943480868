import { SitesImageInCurlyMaskProps } from '../model/types';
import lavrStreet from './lavr-street.png';

export const imageInCurlyMask: SitesImageInCurlyMaskProps = {
  topDescription: `ЭТОТ УГОЛОК ЯКИМАНКИ ЗНАМЕНИТ ЦЕРКВЯМИ, ОСОБНЯКАМИ, ИСТОРИЧЕСКИМИ УЛОЧКАМИ И СТАРЫМИ ЗДАНИЯМИ. ЗДЕСЬ СОХРАНИЛАСЬ 
  УНИКАЛЬНАЯ АТМОСФЕРА ИСТОРИЧЕСКОЙ МОСКВЫ С УХОЖЕННЫМИ ФАСАДАМИ, ШИРОКИМИ ТРОТУАРАМИ И ОБИЛИЕМ ЗЕЛЕНИ.`,
  title: `1 км от кремля, у\u00A0третьяковской галереи`,
  mainColor: 'black',
  description: `Здесь сохранилась уникальная атмосфера исторической Москвы с ухоженными фасадами, широкими тротуарами и
обилием зелени.`,
  imageSrc: lavrStreet,
  isReverse: true,
  backgroundColor: 'grey',
  descriptionColor: 'black',
  imageMask: 'circle',
  linkForButton: 'https://www.sminex.com',
  buttonText: `Подробнее`,
  listInDescription: [
    `полная тишина и приватность`,
    `Clubhouse только для жителей с инфраструктурой для спорта и детских игр`,
    `пространство для здорового образа жизни Fit Lab с 25 метровым бассейном и спа`,
    `детское игровое пространство Kids Lab`,
    `отдельный сервисный лифт для персонала`,
    `самые полные и лучшие системы комфорта`,
    `шикарные лобби`,
    `красивый просторный паркинг`,
    `деликатный сервис высшего уровня.`,
  ],
};
