import { imageInCurlyMask } from '@/features/sites/image-in-curly-mask/api/mock-image-curly-mask';
import { CommonProps, ComponentConfig } from '@/shared/libs/dynamic-components-render';

export const pageConfig = (): ComponentConfig<CommonProps>[] => [
  {
    component: 'SitesImageInCurlyMask',
    props: {
      topDescription: imageInCurlyMask.topDescription,
      title: imageInCurlyMask.title,
      // mainColor: imageInCurlyMask.mainColor,
      description: imageInCurlyMask.description,
      imageSrc: imageInCurlyMask.imageSrc,
      // isReverse: imageInCurlyMask.isReverse,
      // backgroundColor: imageInCurlyMask.backgroundColor,
      //   descriptionColor: imageInCurlyMask.descriptionColor,
      imageMask: imageInCurlyMask.imageMask,
      linkForButton: imageInCurlyMask.linkForButton,
      buttonText: imageInCurlyMask.buttonText,
      listInDescription: imageInCurlyMask.listInDescription,
    },
  },
];
