import React from 'react';
import { renderComponent } from '@/shared/libs/dynamic-components-render';
import { pageConfig } from '../model/mock-test-shamil';

export const TestShamilPage = () => {
  return (
    <div style={{ marginTop: '150px' }}>
      {/*TODO потом заменить див на реакт фрагмент без стилей, поставил временно, чтобы навбар на наезжал сверху */}
      {pageConfig().map((config, index) => (
        <React.Fragment key={index}>{renderComponent(config)}</React.Fragment>
      ))}
    </div>
  );
};
